<template>
  <!-- eslint-disable vue/no-v-html -->
  <signup-fail-template data-test="layout">
    <template #page_title>
      <div data-test="title" v-html="$t(`${langPath}.title`)" />
    </template>
    <template #content>
      <message
        data-test="success_message"
        type="success"
        :text="$t(`${langPath}.success`)"
        icon-name="check-circle"
      />
      <p data-test="description">{{ $t(`${langPath}.line1`) }}</p>
    </template>
  </signup-fail-template>
</template>

<script>
import SignupFailTemplate from './components/SignupFailTemplate'
import Message from './Message'

export default {
  name: 'AccessRequestSent',
  components: {
    SignupFailTemplate,
    Message
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.signup-fail-template {
  &__main {
    &-content {
      &-box {
        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
